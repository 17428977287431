
var sent = false;
$(document).ready(function(){
  $(".phone-call-register").submit(function() {
    if(sent){
      return;
    }
    // FB Pixel
    // fbq('track', 'CompleteRegistration');
    //GTM
    window.dataLayer.push({
      'event': 'CompleteRegistration'
    });
    sent = true;
  });
})
