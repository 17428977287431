
window.openModal = function() {
  $('.modal').addClass('is-active');
  $('html').addClass('is-clipped');
};

$(document).ready(function(){
  $(".navbar-burger").click(function() {
    $(".navbar-menu").toggleClass("is-active");
  });

  $('.top-banners').owlCarousel({
      items: 1,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      // responsive: {
      //   768 : {
      //     items: 1,
      //   }
      // }
  });

  $('#partners').owlCarousel({
      items: 3,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      responsive: {
        768 : {
          items: 5,
        }
      }
  });

  $( ".accordion" ).accordion({
    animate: 200,
    collapsible: true,
    active: false,
    heightStyle: 'content',
    active: 0,
  });

  function showFlash(text){
    //TODO replace with bootstrap alert
    alert(text);
  }

  var modalOpenning = false;
  var modalOpenned = false;
  function toggleModalClasses() {
    var modal = $('.modal');
    modal.toggleClass('is-active');
    $('html').toggleClass('is-clipped');
    modalOpenning = !modalOpenning;
    modalOpenned = true;
  };

  function openModal() {
    $('.modal').addClass('is-active');
    $('html').addClass('is-clipped');
  };

  function closeModal() {
    $('.modal').removeClass('is-active');
    $('html').removeClass('is-clipped');
    modalOpenning = false;
  };

  $(".phone-call-register").on("ajax:success", function(event) {
    closeModal();
    showFlash('Cảm ơn bạn đã đăng ký! Chúng tôi sẽ gọi lại cho bạn ngay khi có thể.');
  }).on("ajax:error", function(event) {
    closeModal();
    showFlash('Xin lỗi có lỗi đã xảy ra. Xin bạn hãy nhắn tin qua facebook hoặc zalo.');
  });

  $('.open-modal').click(toggleModalClasses);
  $('.close-modal').click(toggleModalClasses);

  var footerHeight = $('.footer').height();
  var inTuyenDungPage = (location.pathname == "/tuyen-dung")
  $(window).on("scroll", function() {
    if (!inTuyenDungPage && !modalOpenned && (window.innerHeight + window.scrollY + footerHeight) >= document.body.offsetHeight) {
      toggleModalClasses();
    }
  })
})
